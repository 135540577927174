.notfound_container {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #4D97FF;
    color: white
}

.notfound_text1 {
    margin-top: -7rem;
    font-size: 10rem;
    margin-bottom: 0;
    text-align: center;
}

.notfound_text2 {
    font-size: 18px;
    margin-top: 0rem;
}