.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 6000000;
}

.modal-content {
  background-color: white;
  /* padding: 20px; */
  border-radius: 8px;
  /* position: relative; */
  width: 80%;
  height: 80%;
  background-image: url("../images/banner/websiteBanner.png"); /* Specify the path to your image */
  background-size: 100% 100%;
  background-repeat: no-repeat;
  z-index: 8000000;
}
.modal-operations {
  justify-content: center;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
}

.close-button {
  top: 10px;
  right: 10px;
  z-index: 900000;
  /* width: 100%; */
}

@media screen and (max-width: 991px) {
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 6000000;
  }

  .modal-content {
    background-color: white;
    /* padding: 20px; */
    border-radius: 8px;
    /* position: relative; */
    width: 80%;
    height: 80%;
    background-image: url("../images/banner/mobileBanner.png"); /* Specify the path to your image */
    background-size: 100% 100%;
    background-repeat: no-repeat;
    z-index: 8000000;
  }
  .modal-operations {
    justify-content: center;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .close-button {
    top: 10px;
    right: 10px;
    z-index: 900000;
    /* width: 100%; */
  }
}
