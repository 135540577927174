.button {
	background: #212949;
	border-radius: 26px;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	color: #ffffff;
	outline: none;
	border: 1px solid #212949;
	padding: 10px 30px;
	white-space: nowrap;
	cursor: pointer;
}

.smallButton {
	font-size: 14px;
	padding: 8px 20px;
}

.button:disabled {
	background: #b4c0d0;
	cursor: not-allowed;
}

@media screen and (max-width: 991px) {
	.button {
		font-size: 14px;
		padding: 8px 20px;
	}
}
