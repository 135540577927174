.container {
	padding-top: 100px;
	scroll-margin: 40px;
}

.heading {
	font-weight: 500;
	font-size: 45px;
	line-height: 55px;
	text-align: center;
	color: #313131;
	margin-bottom: 20px;
}

.subHeading {
	font-weight: 500;
	font-size: 18px;
	line-height: 22px;
	text-align: center;
	color: #696969;
	margin-bottom: 20px;
}

.tagsContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	max-width: 100%;
	overflow: scroll;
	-ms-overflow-style: none;
	scrollbar-width: none;
}

.tagsContainer::-webkit-scrollbar {
	display: none;
}

.cardContainer {
	scroll-snap-align: start;
	padding-left: 20px;
	padding-bottom: 180px;
	margin-top: 60px;
	cursor: pointer;
}

.cardContainer:last-child {
	padding-right: 20px;
}

.imgContainer {
	height: 465px;
	width: 381px;
}

.videoContainer {
	height: 465px;
	width: 723px;
	position: relative;
}

.imgCard {
	width: 100%;
	height: 100%;
	object-fit: cover;
	filter: drop-shadow(20px 75px 80px rgba(0, 0, 0, 0.05));
	border-radius: 20px;
}

.videoOverlay {
	background: linear-gradient(119.76deg, rgba(0, 0, 0, 0.7) 25.58%, rgba(0, 0, 0, 0.551003) 56.7%, rgba(0, 0, 0, 0) 95.5%);
	position: absolute;
	inset: 0;
	border-radius: 20px;
}

.playButton {
	font-size: 52px;
	color: #ffffff;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.modal {
	position: fixed;
	inset: 0;
	z-index: 1001;
	background: rgba(0, 0, 0, 0.85);
	transition: all 300ms ease 0s;
	align-items: center;
	justify-content: center;
}

.topBar {
	background-color: rgba(0, 0, 0, 0.5);
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	height: 50px;
	padding-right: 20px;
}

.closeButton {
	background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIj48cGF0aCBkPSJtIDEsMyAxLjI1LC0xLjI1IDcuNSw3LjUgNy41LC03LjUgMS4yNSwxLjI1IC03LjUsNy41IDcuNSw3LjUgLTEuMjUsMS4yNSAtNy41LC03LjUgLTcuNSw3LjUgLTEuMjUsLTEuMjUgNy41LC03LjUgLTcuNSwtNy41IHoiIGZpbGw9IiNGRkYiLz48L3N2Zz4=")
		no-repeat center;
	width: 40px;
	height: 35px;
	cursor: pointer;
	border: none;
	opacity: 0.7;
}

.closeButton:hover {
	opacity: 1;
}

.lightbox {
	position: fixed;
	inset: 0;
	z-index: 2000;
}

.blur {
	background: linear-gradient(90deg, rgba(242, 242, 242, 0.9) 0%, rgba(255, 255, 255, 0) 40.31%);
	position: absolute;
	left: 0px;
	top: 0px;
	width: calc(50vw - 52px);
	bottom: 0;
	z-index: 10;
	pointer-events: none;
}

.rightBlur {
	left: auto;
	right: 0px;
	transform: rotate(180deg);
}

@media screen and (max-width: 991px) {
	.container {
		padding-top: 40px;
		scroll-margin: 40px;
	}
	.heading {
		font-weight: 500;
		font-size: 24px;
		line-height: 29px;
		padding: 0 30px;
		margin-bottom: 20px;
		text-align: left;
	}
	.subHeading {
		display: none;
	}
	.videoContainer {
		height: 170px;
		width: 327px;
	}
	.imgContainer {
		height: 170px;
		width: 140px;
		margin-bottom: 20px;
	}
	.cardContainer {
		padding-bottom: 130px;
		margin-top: 30px;
	}
	.tagsContainer {
		justify-content: flex-start;
	}
	.tagsContainer > div:first-child {
		margin-left: 20px;
	}
}
