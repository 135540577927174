@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
	font-family: "Montserrat";
	font-style: normal;
	-webkit-tap-highlight-color: transparent;
}

.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}



html {
	scroll-behavior: smooth;
}

body {
	margin: 0;
}

.slick-list {
	padding-bottom: 170px !important;
}

.d-flex {
	display: flex !important;
	width: 100%;
}

.align-items-center {
	align-items: center !important;
}

.justify-center {
	justify-content: center !important;
}

.justify-between {
	justify-content: space-between !important;
}

.hideDesktop {
	display: none;
}

.hideMobile {
	display: block;
}

.relative {
	position: relative;
}

.ril-image-current {
	transform: none !important;
}

@media screen and (max-width: 991px) {
	.slick-list {
		padding-bottom: 130px !important;
	}
	.hideDesktop {
		display: block;
	}
	.hideMobile {
		display: none;
	}
}
