.carouselBtnContainer {
	position: absolute;
	bottom: 50px;
	left: 50%;
	transform: translate(-50%, 0);
	background: #ffffff;
	border-radius: 40px;
	padding: 8px;
	display: flex;
	align-items: center;
}

.arrow {
	width: 50px;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.rightArrow {
	background: #212949;
	box-shadow: 0px 8px 15px rgba(72, 72, 138, 0.08);
	border-radius: 40px;
}

@media screen and (max-width: 991px) {
	.carouselBtnContainer {
		bottom: 40px;
	}
}
