.heading {
	font-weight: 600;
	font-size: 45px;
	line-height: 54px;
	color: #515151;
}
.subheading {
	font-weight: 200;
	font-size: 20px;
	line-height: 24px;
	color: #515151;
	margin-top: 10px;
}

.container {
	display: flex;
	align-items: center;
	padding: 50px 126px;
	background-image: url("../../../images/background/register.png");
	background-size: cover;
	padding-bottom: 100px;
	scroll-margin: 70px;
}

.leftContainer {
	width: 65%;
}

.rightContainer {
	width: 35%;
	padding-left: 60px;
}

.formContainer {
	padding: 40px;
	gap: 20px;
	background: #ffffff;
	box-shadow: 10px 10px 84px rgba(0, 0, 0, 0.08);
	border-radius: 20px;
	max-width: 310px;
	width: 100%;
}

.formHeading {
	font-weight: 600;
	font-size: 22px;
	line-height: 27px;
	color: #212949;
	margin-bottom: 20px;
}

.errorText {
	font-size: 12px;
	margin-top: -6px;
	margin-left: 4px;
	color: #ff2222;
}

@media screen and (max-width: 991px) {
	.container {
		padding: 28px 20px;
	}
	.leftContainer {
		display: none;
	}
	.rightContainer {
		width: 100%;
		padding-left: 0;
		display: flex;
		justify-content: center;
	}
	.formHeading {
		text-align: center;
	}
	.formContainer {
		padding: 20px;
	}
}
