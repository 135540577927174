.container {
  padding-top: 100px;
  scroll-margin: 40px;
  background-color: #fafafa;
  margin-left: 15%;
  margin-right: 15%;
}

.heading {
  font-weight: 500;
  font-size: 45px;
  line-height: 55px;
  text-align: center;
  color: #313131;
  margin-bottom: 50px;
}

.imgContainer {
  display: flex;
  width: 50px;
  height: 50px;
  justify-content: center;
  margin-bottom: 24px;
}

.cardHeading {
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  color: #032c63;
  text-align: center;
  margin-bottom: 12px;
}

.cardText {
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #666666;
}

.card {
  padding: 40px 30px;
  background: #ffffff;
  border: 1px solid #ededed;
  border-radius: 20px;
  max-width: calc(80% - 30px);
  /* height: 440px; */
  width: 100px;
  height: 100px;
  transition: box-shadow 0.2s;
}

.active {
  box-shadow: 20px 100px 80px rgba(49, 49, 49, 0.1);
  border: none;
  width: 150px;
  height: 150px;
  /* height: 494px; */
}

@media screen and (max-width: 991px) {
  .container {
    padding-top: 40px;
    background: white;
    margin-left: 5%;
    margin-right: 5%;
  }
  .heading {
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    padding: 0 30px;
    margin-bottom: 30px;
  }

  .cardHeading {
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
  }
  .cardText {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
  .card {
    height: 100%;
    width: 200px;
    height: 200px;
    /* min-height: 430px; */
    margin-bottom: 20px;
  }
  .active {
    height: 100%;
    width: 200px;
    height: 200px;
  }
}
