.container {
  padding-top: 100px;
  padding-bottom: 100px;
  scroll-margin: 40px;
  background-color: #fafafa;
  padding-left: 1%;
  padding-right: 1%;
  justify-content: center;
  align-items: center;
  align-self: center;
  align-content: center;
}

.heading {
  font-weight: 500;
  font-size: 45px;
  line-height: 55px;
  text-align: center;
  color: #313131;
  margin-bottom: 50px;
}

.imgContainer {
  display: flex;
  width: 50px;
  height: 50px;
  justify-content: center;
  margin-bottom: 24px;
}
.uponicons {
  z-index: 3;
  margin-right: -35px;
}
.overSvg {
  z-index: 3;
  font-size: 50px;
  position: absolute;
  color: #fff;
  margin-top: 25px;
  margin-left: 35px;
}

.cardHeading {
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  color: #032c63;
  text-align: center;
  margin-bottom: 12px;
}

.cardText {
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #666666;
}

.card {
  display: flex;
  border-radius: 20px;
  justify-content: center;
  flex-wrap: wrap;
  align-self: center;
  align-items: center;
  transition: box-shadow 0.2s;
}
.gearStyle {
  z-index: 1;
  margin-right: -35px;
}
.attachTitle {
  padding: 0px 200px 0px 90px;
  display: flex;
  color: rgb(5, 89, 100);
  font-size: large;
  background: #ffffff;
  border: 1px solid #ededed;
  border-radius: 20px;
  max-width: calc(60% - 30px);
  margin-left: -10px;
  /* height: 440px; */
  /* width: 100px; */
  /* height: 100px; */
  z-index: 0;
  text-align: start;
  transition: box-shadow 0.2s;
}

.active {
  box-shadow: 20px 100px 80px rgba(49, 49, 49, 0.1);
  border: none;
  width: 150px;
  height: 150px;
  /* height: 494px; */
}

@media screen and (max-width: 991px) {
  .container {
    padding-top: 40px;
    background: white;
    padding-left: 1%;
    padding-right: 1%;
    padding-bottom: 40px;
  }
  .heading {
    margin: 20px 0;
    padding: 0 35px;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    text-align: left;
    margin-bottom: 30px;
  }

  .card {
    display: flex;
    border-radius: 20px;
    justify-content: center;

    align-self: center;
    align-items: center;
    transition: box-shadow 0.2s;
    margin-bottom: 10px;
  }
  .overSvg {
    z-index: 3;
    font-size: 20px;
    position: absolute;
    color: #fff;
    margin-top: 15px;
    margin-left: 35px;
  }
  .attachTitle {
    padding: 0px 50px 0px 50px;
    display: flex;
    color: rgb(5, 89, 100);
    font-size: x-small;
    background: #ffffff;
    border: 1px solid #ededed;
    border-radius: 20px;
    /* max-width: calc(50% - 10px); */
    margin-left: -0px;
    /* height: 440px; */
    /* width: 100px; */
    /* height: 100px; */
    z-index: 0;
    text-align: start;
    transition: box-shadow 0.2s;
  }
  .active {
    height: 100%;
    width: 200px;
    height: 200px;
  }
}
