.heading {
  font-weight: 600;
  font-size: 48px;
  line-height: 72px;
  color: #212949;
}

.banner_video {
  /* position: sticky;
  object-fit: cover;
  width: 100%;
  height: 60%;
  top: 0;
  left: 0;
  opacity: 0.5;
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
  z-index: -99999; */
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
}
.overlay-content {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(6, 56, 82, 0.5);
  width: 100%;
  height: 60%;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    255,
    255,
    255,
    0.5
  ); /* White overlay with 50% opacity */
  z-index: -99999; /* Place the overlay behind the text */
}

.subHeading {
  font-weight: 400;
  font-size: 20px;
  line-height: 38px;
  color: #212949;
  margin-top: 24px;
  padding-right: 100px;
}

.container {
  padding-top: 94px;
  padding-bottom: 0px;
  padding-right: 94px;
  padding-left: 94px;

  /* padding: 94px 106px; */
}

.leftContainer {
  width: 55%;
}

.rightContainer {
  width: 45%;
}

.rightContainer > img {
  width: 100%;
  height: auto;
  margin-top: -150px;
  margin-left: 0px;
}

.streamContainer {
  display: flex;
  align-items: center;
  margin-top: 12px;
}

.stream {
  font-weight: 500;
  font-size: 24px;
  line-height: 38px;
  color: #212949;
  display: flex;
  align-items: center;
}

.stream:not(:first-child) {
  margin-left: 72px;
}

.stream > img {
  margin-right: 16px;
  width: 40px;
  height: 40px;
}

.ctaContainer {
  margin-top: 40px;
  margin-bottom: 80px;
  display: flex;
  align-items: center;
}

.highlightContainer {
  display: inline-grid;
  grid-template-columns: repeat(4, 200px);
  grid-gap: 20px;
}

.highlight {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
  background: #eef6ff;
  border-radius: 2px 20px;
}

.highlightLabel {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  white-space: nowrap;
}

.highlightValue {
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  color: #000000;
  margin-top: 10px;
}

.explore {
  display: none;
}

@media screen and (max-width: 991px) {
  .container {
    padding: 28px 20px;
  }

  .heading {
    font-size: 20px;
    line-height: 24px;
  }

  .subHeading {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-top: 12px;
    padding-right: 0px;
  }

  .leftContainer {
    width: 100%;
  }

  .rightContainer {
    display: none;
  }

  .stream {
    font-weight: 500;
    font-size: 14px;
    line-height: 38px;
  }

  .streamContainer {
    margin-top: 20px;
  }

  .explore {
    margin-left: 24px;
    text-decoration: underline;
    font-size: 14px;
    cursor: pointer;
    display: block;
  }

  .exploreBtn {
    display: none;
  }

  .highlightContainer {
    grid-template-columns: repeat(2, 50%);
    width: calc(100% - 20px);
  }

  .highlightLabel {
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    color: #000000;
    white-space: break-spaces;
  }

  .highlightValue {
    font-weight: 700;
    font-size: 22px;
    line-height: 27px;
    color: #717171;
  }

  .highlight {
    padding: 10px;
  }

  .stream > img {
    margin-right: 12px;
    width: 32px;
    height: 32px;
  }

  .ctaContainer {
    margin-top: 26px;
    margin-bottom: 56px;
  }
}
