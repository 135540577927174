.container {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 20px 40px;
	background: #f3f3f3;
}

.text {
	font-weight: 500;
	font-size: 36px;
	line-height: 44px;
	color: #313131;
	margin-right: 40px;
}

@media screen and (max-width: 991px) {
	.container {
		flex-direction: column;
		padding: 40px 16px;
	}
	.text {
		text-align: center;
		margin-right: 0px;
		margin-bottom: 40px;
		font-weight: 500;
		font-size: 36px;
		line-height: 44px;
	}
}
