.container {
  padding: 130px 106px 0;
  scroll-margin: 70px;
  height: 100vh;
  margin-top: 60px;
}

.heading {
  font-weight: 500;
  font-size: 48px;
  line-height: 180%;
  color: #262626;
}

.text {
  font-weight: 400;
  font-size: 24px;
  line-height: 45px;
  color: #646464;
}

.leftContainer {
  width: 60%;
}

.rightContainer {
  width: 40%;
  padding-left: 100px;
}

.rightContainer > img {
  width: 100%;
  height: auto;
}

.readMore {
  font-weight: 500;
  font-size: 24px;
  line-height: 220%;
  color: #000000;
  display: flex;
  align-items: center;
  margin-top: 70px;
  cursor: pointer;
}

@media screen and (max-width: 991px) {
  .container {
    padding: 28px 20px;
    display: block;
  }
  .leftContainer {
    width: 100%;
  }
  .rightContainer {
    width: 100%;
    padding-left: 0;
    margin-top: 20px;
    max-width: 420px;
    margin-left: auto;
    margin-right: auto;
  }
  .formHeading {
    text-align: center;
  }
  .heading {
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color: #212949;
  }
  .text {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #212949;
    margin-top: 22px;
  }
  .readMore {
    margin-top: 20px;
    font-weight: 500;
    font-size: 14px;
    line-height: 30px;
    text-decoration-line: underline;
  }
}
