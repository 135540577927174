.scrollerWrapper {
	display: flex;
	overflow: auto;
	scroll-behavior: smooth;
	scroll-snap-type: x mandatory;
	-ms-overflow-style: none;
	scrollbar-width: none;
}

.scrollerWrapper::-webkit-scrollbar {
	display: none;
}

.arrowWrapper {
	position: absolute;
	bottom: 0px;
	left: 50%;
	bottom: 50px;
	transform: translate(-50%, 0);
	background: #ffffff;
	border-radius: 40px;
	padding: 8px;
	display: flex;
	align-items: center;
}

.arrow {
	width: 50px;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.rightArrow {
	background: #212949;
	box-shadow: 0px 8px 15px rgba(72, 72, 138, 0.08);
	border-radius: 40px;
}

@media screen and (max-width: 991px) {
	.scrollerWrapper {
		padding: 0;
	}

	.arrowWrapper {
		bottom: 40px;
	}
}
