.container {
  background: rgb(213, 213, 213);
  padding-top: 30px;
  /* padding: 76px 10px 10px; */
  /* scroll-margin: 70px; */
  /* overflow-x: visible; */
  width: 100%;
  /* flex-wrap: nowrap; */
  padding-bottom: 40px;
}

.carouselContainer {
  overflow-x: auto;
  white-space: nowrap;
  padding: 10px; /* Optional: Add padding to the container */
}
.carouselContainer::-webkit-scrollbar {
  display: none;
}

.heading {
  font-weight: 500;
  font-size: 45px;
  line-height: 55px;
  text-align: center;
  color: #313131;
  margin-bottom: 50px;
}

.cardHeading {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #d67840;
}

.cardText {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: -0.01em;
  color: #6a635b;
  margin: 18px 0;
}

.card {
  padding: 48px 28px;
  background: #f5f5f5;
  border-radius: 24px;
  /* max-width: calc(30%); */
  margin: 34px;

  /* min-height: 230px; */
}

.active {
  padding: 55px 58px;
  max-width: calc(80% - 60px);
  min-height: 290px;
  margin-top: 0px;
}

.blur {
  /* background: linear-gradient(90deg, #eef1fe 33.33%, rgba(242, 242, 242, 0) 100%, rgba(232, 235, 248, 0) 100%); */
  position: absolute;
  left: 0px;
  top: 0px;
  width: 208px;
  bottom: 0;
  z-index: 10;
}

.rightBlur {
  left: auto;
  right: 0px;
  transform: rotate(180deg);
}

.userContainer {
  display: flex;
  align-items: center;
  margin-top: 18px;
}

.profileImg {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.user {
  font-family: "Inter";
  font-style: normal;
  font-size: 16px;
  line-height: 19px;
  color: #516686;
}

.user > span {
  font-weight: 600;
  color: #32435c;
}

.institution {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #787878;
  margin-top: 4px;
}

@media screen and (max-width: 991px) {
  .container {
    background: rgb(213, 213, 213);
    padding-top: 30px;
    width: 100%;
  }
  .carouselContainer {
    /* background: #eff2ff; */
    padding-top: 50px;
    padding-bottom: 10px;
    /* display: block; */
  }
  .carouselContainer::-webkit-scrollbar {
    display: none;
  }
  .blur {
    display: none;
  }
  .active {
    min-height: 230px;
  }
  .card {
    max-width: calc(80% - 20px);
    padding: 20px;
    margin-top: 0px;
    margin-bottom: 20px;
    min-width: 80%;
  }
  .heading {
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    padding: 0 30px;
    margin-bottom: 30px;
  }
  .userContainer {
    display: block;
    display: flex;
    /* overflow-x: scroll; */
  }
  .user {
    margin-top: 14px;
    /* background-color: #313131; */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    /* width: 80%; */
    white-space: initial;
  }
  .cardText {
    font-weight: 400;
    font-size: 14px;
    line-height: 23px;
    letter-spacing: -0.01em;
    margin: 12px 0;
  }
}
