.navbar {
	background: rgba(255, 255, 255, 0.9) !important;
	box-shadow: 0px 20px 58px rgba(0, 0, 0, 0.08);
	backdrop-filter: blur(6px);
	padding: 0 106px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: fixed;
	background: #ffffff;
	top: 0;
	left: 0;
	right: 0;
	max-height: 100px;
	min-height: 100px;
	z-index: 1000;
}

.children {
	flex-grow: 1;
	position: relative;
	padding-top: 100px;
}

.navbar > div > a {
	font-weight: 400;
	font-size: 16px;
	line-height: 16px;
	color: #212949;
	text-decoration: none;
	margin: 0 2vw;
	white-space: nowrap;
}

.navLinks {
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
}

.headerClassLogo {
	margin-left: 24px;
	max-width: 164px;
	object-fit: contain;
	width: 33vw;
}

.headerRoboLogo {
	max-width: 165px;
	object-fit: contain;
	width: 33vw;
	cursor: pointer;
}

.hamburger {
	display: none;
}

.footerContainer {
	padding: 64px 128px 22px;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

.about {
	display: flex;
	flex-direction: column;
	max-width: 268px;
}

.resources {
	display: flex;
	flex-direction: column;
	max-width: 109px;
}

.company {
	display: flex;
	flex-direction: column;
	max-width: 103px;
}

.subscribe {
	display: flex;
	flex-direction: column;
	max-width: 295px;
	flex-grpw: 1;
	width: 33%;
}

.footerRoboLogo {
	width: 165px;
	object-fit: contain;
	height: 60px;
}

.footerClassLogo {
	width: 170px;
	object-fit: contain;
	margin-top: -10px;
}

.footerLogoContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.footerText {
	font-weight: 500;
	font-size: 16px;
	line-height: 22px;
	color: #313131;
	margin-top: 30px;
	text-decoration: none;
}

.footerHeading {
	font-weight: 600;
	font-size: 20px;
	line-height: 24px;
	color: #1c1c1d;
}

.footerDivider {
	border-top: 1px solid #ededed;
	height: 0px;
	width: calc(100% - 171px * 2);
	margin: 64px auto 22px;
}

.copyright {
	width: 100%;
	text-align: center;
	font-weight: 500;
	font-size: 16px;
	line-height: 100%;
	color: #313131;
}

.availabeIn {
	display: flex;
	font-weight: 500;
	font-size: 12px;
	line-height: 20px;
	color: #313131;
	margin-top: 24px;
	width: 100%;
}

.sidebarClassLogo {
	width: 130px;
	object-fit: contain;
	margin-top: -32px;
}

.sidebarRoboLogo {
	width: 130px;
	object-fit: contain;
	margin-top: -20px;
	height: 60px;
}

.closeIcon {
	position: absolute;
	right: 22px;
	font-size: 18px;
	cursor: pointer;
}

.modalBody {
	display: flex;
	position: relative;
}

.teacherContainer {
	background: #4a6d8b;
	width: 50%;
	padding: 62px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: #ffffff;
	font-weight: 500;
	font-size: 24px;
	line-height: 38px;
	text-align: center;
}

.teacherContainer > div {
	margin-bottom: 44px;
}

.studentContainer {
	background: #e6f7d5;
	width: 50%;
	padding: 62px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: #000000;
	font-weight: 500;
	font-size: 24px;
	line-height: 38px;
	text-align: center;
}

.studentContainer > div {
	margin-bottom: 44px;
}

.loginButtonStudent {
	font-weight: 500;
	font-size: 18px;
	line-height: 22px;
	color: #000000;
	background: transparent;
	border: 2px solid;
	border-color: #000000;
	padding: 10px 14px;
	border-radius: 100px;
	text-decoration: none;
}

.loginButtonTeacher {
	font-weight: 500;
	font-size: 18px;
	line-height: 22px;
	color: #ffffff;
	background: transparent;
	border: 2px solid;
	border-color: #ffffff;
	padding: 10px 14px;
	border-radius: 100px;
	text-decoration: none;
}


.loginButtonTeacher:hover {
	color: #ffffff;
}

.loginButtonStudent:hover {
	color: #000000;
}

.modalCloseIcon {
	position: absolute;
	right: 22px;
	top: 22px;
	font-size: 18px;
	cursor: pointer;
}

.whatsApp {
	font-size: xx-large;
	color: green;
	margin: 0px 30px 0px;
}

@media screen and (max-width: 1232px) {
	.navbar {
		padding: 0 18px;
		max-height: 72px;
		min-height: 72px !important;
		justify-content: space-between;
	}
	.navLinks {
		display: none;
	}
	.whatsApp {
		font-size: x-large;
		color: green;
		margin: 0px 15px 0px;
	}
	.hamburger {
		display: block;
		width: 44px;
		height: 44px;
	}
	.footerContainer {
		padding: 24px;
	}
	.about {
		max-width: none;
		width: 100%;
	}
	.resources {
		max-width: none;
		width: 49%;
	}
	.company {
		max-width: none;
		width: 49%;
	}
	.footerHeading {
		margin-top: 30px;
		font-weight: 600;
		font-size: 18px;
		line-height: 22px;
	}
	.footerText {
		margin-top: 20px;
		font-weight: 500;
		font-size: 14px;
		line-height: 14px;
	}
	.about > .footerText {
		text-align: center;
	}
	.children {
		padding-top: 72px;
	}
	.availabeIn {
		display: block;
	}
	.footerDivider {
		width: calc(100% - 51px * 2);
		margin: 30px auto 20px;
	}
	.copyright {
		font-size: 16px;
		line-height: 24px;
	}
	.sidebarDivider {
		border-top: 1px solid #cbcbcb;
		width: 100%;
		margin-top: 10px;
		margin-bottom: 22px;
	}
	.sidebarLink {
		font-weight: 400;
		font-size: 12px;
		line-height: 15px;
		text-transform: capitalize;
		color: #141414;
		cursor: pointer;
		margin-bottom: 24px;
	}
	.modalBody {
		flex-wrap: wrap;
	}
	.teacherContainer {
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		width: 100%;
		padding: 34px;
	}
	.teacherContainer > div {
		margin-bottom: 20px;
	}
	.studentContainer {
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		width: 100%;
		padding: 34px;
	}
	.studentContainer > div {
		margin-bottom: 20px;
	}
	.loginButton {
		font-weight: 500;
		font-size: 14px;
		line-height: 17px;
	}
}
